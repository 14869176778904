import { SVGProps } from 'react'

export const IconApple = ({
  color = 'currentColor',
  size = 24,
  ...props
}: SVGProps<SVGSVGElement> & { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9084 4.7726C11.8272 4.8356 10.3936 5.6434 10.3936 7.4396C10.3936 9.5172 12.2178 10.2522 12.2724 10.2704C12.264 10.3152 11.9826 11.277 11.3106 12.257C10.7114 13.1194 10.0856 13.9804 9.1336 13.9804C8.1816 13.9804 7.9366 13.4274 6.8376 13.4274C5.7666 13.4274 5.3858 13.9986 4.515 13.9986C3.6442 13.9986 3.0366 13.2006 2.338 12.2206C1.5288 11.0698 0.875 9.282 0.875 7.5852C0.875 4.8636 2.6446 3.4202 4.3862 3.4202C5.3116 3.4202 6.083 4.0278 6.664 4.0278C7.217 4.0278 8.0794 3.3838 9.1322 3.3838C9.5312 3.3838 10.9648 3.4202 11.9084 4.7726ZM8.6324 2.2316C9.0678 1.715 9.3758 0.9982 9.3758 0.2814C9.3758 0.182 9.3674 0.0812 9.3492 0C8.6408 0.0266 7.798 0.4718 7.2898 1.0612C6.8908 1.5148 6.5184 2.2316 6.5184 2.9582C6.5184 3.0674 6.5366 3.1766 6.545 3.2116C6.5898 3.22 6.6626 3.2298 6.7354 3.2298C7.371 3.2298 8.1704 2.8042 8.6324 2.2316Z"
      fill={color}
    />
  </svg>
)
