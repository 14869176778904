import { ReactNode } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './i18n/config'
import { Home } from './pages/Home'
import { DefaultSEO } from './sections/DefaultSEO'
import { Jobs } from './pages/Jobs'

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={`
        bg-[url('assets/hero/hero-background-mobile.webp'),_url('assets/footer/footer-background-mobile.webp')]
        bg-[length:375px_528px,375px_257px]
        md:bg-[url('assets/hero/hero-background.webp'),_url('assets/footer/footer-background-tablet.webp')]
        md:bg-[length:2556px_980px,1024px_482px]
        lg:bg-[url('assets/hero/hero-background.webp'),_url('assets/footer/footer-background.webp')]
        lg:bg-[length:2556px_980px,2559px_650px]
        bg-no-repeat
        bg-[position:center_top,center_bottom]

        md:bg-[position:center_top,center_bottom]
      `}
    >
      {children}
    </div>
  )
}

function App() {
  return (
    <>
      <DefaultSEO />
      <Layout>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/jobs" element={<Jobs />} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </>
  )
}

export default App
