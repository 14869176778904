import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import App from './App'
import { BreakpointProvider } from './hooks/useBreakpoint'
import './index.css'

if (process.env.REACT_APP_GA_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BreakpointProvider>
      <App />
    </BreakpointProvider>
  </React.StrictMode>
)
