import { SVGProps } from 'react'

export const IconArrowTopRight = ({
  color = 'currentColor',
  size = 24,
  ...props
}: SVGProps<SVGSVGElement> & { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 10 10" fill="none" {...props}>
    <path
      d="m1.668 8.438 6.63-6.63m0 0H2.046m6.25 0v6.25"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
