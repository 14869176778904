import React, { useState, useEffect, createContext, useContext } from 'react'

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
}

type BreakpointKey = keyof typeof breakpoints

const defaultValue = {
  sm: false,
  md: false,
  lg: false,
  xl: false,
}

const BreakpointContext = createContext(defaultValue)

const mediaQueryLists = {
  sm: window.matchMedia(`(min-width: ${breakpoints.sm})`),
  md: window.matchMedia(`(min-width: ${breakpoints.md})`),
  lg: window.matchMedia(`(min-width: ${breakpoints.lg})`),
  xl: window.matchMedia(`(min-width: ${breakpoints.xl})`),
}

export function BreakpointProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [queryMatch, setQueryMatch] = useState({
    sm: mediaQueryLists.sm.matches,
    md: mediaQueryLists.md.matches,
    lg: mediaQueryLists.lg.matches,
    xl: mediaQueryLists.xl.matches,
  })

  useEffect(() => {
    const listener = () => {
      setQueryMatch({
        sm: mediaQueryLists.sm.matches,
        md: mediaQueryLists.md.matches,
        lg: mediaQueryLists.lg.matches,
        xl: mediaQueryLists.xl.matches,
      })
    }

    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  )
}

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const context = useContext(BreakpointContext)
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider')
  }
  return context[breakpointKey]
}
