import { useTranslation } from 'react-i18next'
import logo from '../assets/logo-text.svg'
import { Button } from '../components/Button'
import i18n from '../i18n/config'
import { urls } from '../config'

// Manually change this number to show a badge on the jobs button
const OPEN_VACANCIES = 0

export function Header() {
  const { t } = useTranslation(['header', 'common'])
  return (
    <header className="p-5 sm:px-8 sm:py-6 flex justify-between items-center">
      <a href="/">
        <img src={logo} className="h-6 sm:h-8 w-auto" alt="Peakfijn logo" />
      </a>
      <Button
        variant="ghost"
        className="ml-auto mr-3"
        onClick={() =>
          i18n.changeLanguage(i18n.resolvedLanguage === 'nl' ? 'en' : 'nl')
        }
      >
        {t('changeLanguage')}
      </Button>
      <div className="relative">
        <Button variant="outline" as="a" href={urls.jobs}>
          {t('jobs')}
        </Button>
        {OPEN_VACANCIES > 0 && (
          <div className="w-[1.125rem] h-[1.125rem] rounded-full border border-neutral-0 bg-orange-600 flex items-center justify-center absolute -top-2 -right-2">
            <span className="text-xs leading-none font-bold font-display text-white">
              1
            </span>
          </div>
        )}
      </div>
    </header>
  )
}
