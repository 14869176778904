import { useBreakpoint } from '../hooks/useBreakpoint'
import { Button } from '../components/Button'
import { useTranslation } from 'react-i18next'

export function CallToAction() {
  const isMediumDevice = useBreakpoint('sm')
  const { t } = useTranslation('callToAction')

  return (
    <section className="outer-container mt-[4rem]">
      <div className="flex flex-col items-center justify-center py-[3rem] md:py-[6rem] lg:py-[9rem] border bg-white border-neutral-600/10 rounded-xl lg:rounded-2xl shadow-md md:-mx-12 lg:mx-0">
        <h1 className="font-display font-bold text-xl lg:text-4xl leading-tight text-center">
          {t('title.part1')}
          <br />
          {t('title.part2')}
          {!isMediumDevice ? <br /> : ''}
          <em className="text-orange-600 not-italic"> {t('title.part3')}</em>
        </h1>
        <Button
          className="mt-5 md:mt-8 mx-auto"
          as="a"
          size={isMediumDevice ? 'large' : 'medium'}
          href={`mailto:${t('button.email')}`}
        >
          {t('button.text')}
        </Button>
      </div>
    </section>
  )
}
