import { useTranslation } from 'react-i18next'
import logo from '../assets/logo-text.svg'

export function Footer() {
  const { t } = useTranslation('footer')

  return (
    <footer className="py-5 md:py-10 flex justify-between items-center m-auto max-w-2xl lg:max-w-inner px-5 lg:px-0">
      <img src={logo} className="h-6 lg:h-8 w-auto" alt="Peakfijn logo" />
      <nav className="md:space-x-6 flex flex-col md:flex-row">
        <a
          className="text-xs md:text-sm text-neutral-900 hover:text-neutral-800"
          href="https://goo.gl/maps/nWy8Q5RaUe1EsY528"
        >
          {t('city')}
        </a>

        <a
          className="text-xs md:text-sm text-neutral-900 hover:text-neutral-800"
          href={`tel:${t('phoneFormatted')}`}
        >
          {t('phone')}
        </a>
        <a
          className="text-xs md:text-sm text-neutral-900 hover:text-neutral-800"
          href={`mailto:${t('email')}`}
        >
          {t('email')}
        </a>
      </nav>
    </footer>
  )
}
