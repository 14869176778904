import { useTranslation } from 'react-i18next'

export function JobsVacancies() {
  const { t } = useTranslation('jobs')

  return (
    <section className="mt-10 lg:mt-[220px]">
      <div className="outer-container">
        <h2 className="h2">{t('vacancies.title')}</h2>
        <p className="subtitle mb-20">{t('vacancies.subtitle')}</p>

        <a href={t('url')}>
          <div className="overflow-hidden bg-white shadow-md sm:rounded-xl hover:bg-neutral-100">
            <div className="flex justify-between gap-x-6 px-6 py-5 hover:bg-gray-50 sm:px-12">
              <p className="text-neutral-800">
                {t('vacancies.openApplication')}
              </p>
              <p className="text-orange-400">
                {t('vacancies.fullTimePartTime')}
              </p>
            </div>
          </div>
        </a>
      </div>
    </section>
  )
}
