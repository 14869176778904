import { urls } from '../config'
import { CallToAction } from '../sections/CallToAction'
import { Footer } from '../sections/Footer'
import { Header } from '../sections/Header'
import { JobsHero } from '../sections/JobsHero'
import { JobsVacancies } from '../sections/JobsVacancies'
import { Team } from '../sections/Team'

export const Jobs = () => {
  return (
    <>
      <Header />
      <JobsHero />
      <JobsVacancies />
      <Team showCheckoutVacancies={false} vacanciesUrl={urls.mailto} />
      <CallToAction />
      <Footer />
    </>
  )
}
