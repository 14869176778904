import { SVGProps } from 'react'

export const IconTimes = ({
  color = 'currentColor',
  size = 24,
  ...props
}: SVGProps<SVGSVGElement> & { color?: string; size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={(24 / size) * 1.5}
      d="m.75 23.249 22.5-22.5M23.25 23.249.75.749"
    />
  </svg>
)
