export const metaData = {
  title: 'Peakfijn • Top-notch software for startups',
  description:
    'We are Peakfijn. We help aspiring tech entrepreneurs succeed by investing knowledge, skills and time into starting and scaling ventures.',
}

export const urls = {
  jobs: './jobs',
  mailto: 'mailto:hello@peakfijn.nl',
}
