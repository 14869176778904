import playIcon from '../assets/video/play-icon.webp'

export function PlayButton({
  className,
  ...props
}: React.ComponentProps<'button'>) {
  let classes = `
    group 
    flex 
    justify-center 
    items-center 
    font-display 
    font-bold 
    rounded-full 
    focus:outline-none 
    focus:ring-0 
    select-none 
    bg-white 
    text-orange-600 
    hover:text-orange-700 
    active:text-orange-800
    active:bg-neutral-100 
    disabled:bg-neutral-500 
    h-10
    md:h-12 
    px-4
    md:px-6
    text-sm
    md:text-base
    ${className}
  `

  return (
    <button className={classes} {...props}>
      <div className="mr-2 sm:w-4 sm:h-4 w-3 h-3">
        <img
          src={playIcon}
          alt="Play"
          className="w-full h-full object-contain"
        />
      </div>
      <div className="whitespace-nowrap relative t-0.5">{props.children}</div>
    </button>
  )
}
