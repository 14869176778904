import { SVGProps } from 'react'

export const IconMinus = ({
  color = 'currentColor',
  size = 24,
  ...props
}: SVGProps<SVGSVGElement> & { color?: string; size?: number }) => (
  <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <g
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={(24 / size) * 1.5}
      stroke={color}
    >
      <line x1="0.75" y1="12" x2="23.25" y2="12" />
    </g>
  </svg>
)
