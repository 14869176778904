import { CallToAction } from '../sections/CallToAction'
import { Cases } from '../sections/Cases'
import { FAQ } from '../sections/FAQ'
import { Footer } from '../sections/Footer'
import { Header } from '../sections/Header'
import { Hero } from '../sections/Hero'
import { Team } from '../sections/Team'
import { Video } from '../sections/Video'

export const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Cases />
      <Video />
      <Team />
      <FAQ />
      <CallToAction />
      <Footer />
    </>
  )
}
