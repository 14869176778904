import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef } from 'react'
import { useBreakpoint } from '../hooks/useBreakpoint'
import { CloseButton } from './CloseButton'

interface Props {
  onClose(): void
}

type RequestFullscreen = typeof document.documentElement.requestFullscreen

declare global {
  interface HTMLElement {
    webkitRequestFullscreen: RequestFullscreen
    mozRequestFullScreen: RequestFullscreen
    msRequestFullscreen: RequestFullscreen
  }
}

export function VideoModal({ onClose }: Props) {
  const isLargeScreen = useBreakpoint('md')
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (isLargeScreen || !videoRef.current) return

    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen()
    } else if (videoRef.current.mozRequestFullScreen) {
      /* Firefox */
      videoRef.current.mozRequestFullScreen()
    } else if (videoRef.current.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      videoRef.current.webkitRequestFullscreen()
    } else if (videoRef.current.msRequestFullscreen) {
      /* IE/Edge */
      videoRef.current.msRequestFullscreen()
    }
  }, [isLargeScreen])

  return (
    <Transition show appear>
      <Transition.Child
        enter="transition duration-100 ease-out"
        enterFrom="transform sm:scale-95 opacity-0"
        enterTo="transform sm:scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform sm:scale-100 opacity-100"
        leaveTo="transform sm:scale-95 opacity-0"
        as={Fragment}
      >
        <Dialog onClose={onClose} className="fixed inset-0 z-20">
          <Dialog.Panel
            className={`
              absolute
              inset-0
              flex
              items-center
              justify-center
              bg-white
              md:p-20
            `}
          >
            <CloseButton
              className={
                'fixed bg-white top-6 right-6 sm:absolute sm:top-7 sm:right-7'
              }
              onClick={onClose}
              type="button"
            />
            <video
              src="https://peakfijn.s3.eu-west-1.amazonaws.com/video/video.mp4"
              controls
              autoPlay
              className="w-full h-full object-center object-contain"
            />
          </Dialog.Panel>
        </Dialog>
      </Transition.Child>
      <Transition.Child
        enter="transition-opacity ease-linear duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={Fragment}
      >
        <div
          className="fixed inset-0 bg-orange-800/50 z-20"
          aria-hidden="true"
          onClick={onClose}
        />
      </Transition.Child>
    </Transition>
  )
}
