import videoPreview from '../assets/video/video-preview.webp'
import videoPreviewSmall from '../assets/video/video-preview-small.webp'
import { useBreakpoint } from '../hooks/useBreakpoint'
import { PlayButton } from '../components/PlayButton'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { VideoModal } from '../components/VideoModal'

const logos = [
  {
    src: require('../assets/video/logo-sman.webp'),
    alt: 'SMan Waarnemingen logo',
  },
  {
    src: require('../assets/video/logo-pro-parents.webp'),
    alt: 'Pro Parents logo',
  },
  {
    src: require('../assets/video/logo-split-a-gift.webp'),
    alt: 'Split-A-Gift logo',
  },
  {
    src: require('../assets/video/logo-oaky.webp'),
    alt: 'Oaky logo',
  },
  {
    src: require('../assets/video/logo-sfered.webp'),
    alt: 'Sfered logo',
  },
  {
    src: require('../assets/video/logo-shleep.webp'),
    alt: 'Shleep logo',
  },
]

export function Video() {
  const isLargeDevice = useBreakpoint('md')
  const { t } = useTranslation('video')
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  return (
    <section className="bg-[url('assets/video/pattern-background.webp')] bg-[length:2560px_704px] bg-no-repeat bg-top mt-20 lg:mt-[7.5rem]">
      <div className="outer-container">
        <div
          className={`
            border
            bg-white
            border-neutral-600/10
            rounded-xl
            xl:rounded-2xl
            shadow-md
            -mx-3
            pt-10
            pb-[8rem]
            -mb-[6rem]
            md:-mx-12
            lg:pt-20
            lg:pb-[12rem]
            lg:-mb-[12rem]
            lg:-mx-4
            xl:-mx-[3rem]
            2xl:-mx-[8rem]
          `}
        >
          <h2 className="h2">{t('title')}</h2>
          <p className="subtitle">{t('subtitle')}</p>
        </div>

        <div className="relative w-full rounded-lg md:rounded-xl lg:mt-16">
          <div
            className="absolute inset-0 flex items-center justify-center"
            onClick={() => setIsOverlayVisible(true)}
          >
            <PlayButton className="shadow-lg">{t('play')}</PlayButton>
          </div>
          <img
            src={isLargeDevice ? videoPreview : videoPreviewSmall}
            alt="Onze klanten"
            className="rounded-lg md:rounded-xl"
          />
        </div>
        <div className="mt-8 md:mt-16 grid grid-cols-3 md:grid-cols-6 gap-y-4">
          {logos.map((logo) => (
            <div className="h-6 md:h-9 text-center" key={logo.alt}>
              <img
                loading="lazy"
                src={logo.src}
                alt={logo.alt}
                className="object-contain w-full h-full"
              />
            </div>
          ))}
        </div>
      </div>
      {isOverlayVisible && (
        <VideoModal onClose={() => setIsOverlayVisible(false)} />
      )}
    </section>
  )
}
