import { IconTimes } from './IconTimes'

export function CloseButton({
  className = '',
  ...props
}: React.ComponentProps<'button'>) {
  return (
    <button
      className={`
      flex
      justify-center
      items-center
      rounded
      text-neutral-800
      hover:text-neutral-900
      active:text-neutral-800
      active:bg-neutral-200
      focus:outline-none
      focus:ring-0
      select-none
      disabled:bg-transparent
      disabled:text-neutral-400
      w-12
      h-12
      ${className}
    `}
      {...props}
    >
      <IconTimes size={20} />
    </button>
  )
}
