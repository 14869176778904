import { SVGProps } from 'react'

export const IconGoogle = ({
  color = 'currentColor',
  size = 24,
  ...props
}: SVGProps<SVGSVGElement> & { color?: string; size?: number }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    width={size}
    height={size}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M7.125 6.124v2.1h3.545c-.143.901-1.071 2.64-3.545 2.64A3.874 3.874 0 0 1 3.251 7a3.874 3.874 0 0 1 3.874-3.865c1.214 0 2.027.505 2.492.944l1.695-1.602C10.223 1.48 8.812.875 7.125.875 3.669.875.875 3.613.875 6.999c0 3.387 2.794 6.125 6.25 6.125 3.607 0 6-2.485 6-5.985 0-.402-.044-.71-.098-1.015H7.125Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v13.999H0z" />
      </clipPath>
    </defs>
  </svg>
)
