import { Disclosure, Transition } from '@headlessui/react'
import { Trans, useTranslation } from 'react-i18next'
import { IconMinus } from '../components/IconMinus'
import { IconPlus } from '../components/IconPlus'
import { useBreakpoint } from '../hooks/useBreakpoint'

export function FAQ() {
  const isLargeDevice = useBreakpoint('md')
  const { t } = useTranslation('faq')

  return (
    <section className="mt-[5.25rem] lg:mt-[10rem]">
      <div className="outer-container">
        <h2 className="h2">{t('title')}</h2>
        <p className="subtitle">
          <Trans
            i18nKey="faq:subtitle"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="mailto:hello@peakfijn.nl"
                  target="_blank"
                  className="underline text-orange-600"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>

        <div className="mt-8 md:mt-20 max-w-4xl mx-auto">
          {(
            t('items', { returnObjects: true }) as {
              question: string
              answer: string
            }[]
          ).map(({ question, answer }) => (
            <Disclosure key={question}>
              {({ open }) => (
                <div
                  className={`border-b ${
                    open ? 'border-transparent' : 'border-neutral-100'
                  } last:border-transparent`}
                >
                  <Disclosure.Button className="text-left font-display font-sm md:text-xl text-neutral-800 py-3 md:py-8 w-full flex items-center justify-between">
                    <p className="mr-4">{question}</p>
                    <span className="text-orange-500">
                      {open ? (
                        <IconMinus size={isLargeDevice ? 24 : 14} />
                      ) : (
                        <IconPlus size={isLargeDevice ? 24 : 14} />
                      )}
                    </span>
                  </Disclosure.Button>
                  <Transition
                    className="overflow-hidden"
                    enter="transition transition-[max-height] duration-500 ease-in"
                    enterFrom="transform max-h-0"
                    enterTo="transform max-h-screen"
                    leave="transition transition-[max-height] duration-500 ease-out"
                    leaveFrom="transform max-h-screen"
                    leaveTo="transform max-h-0"
                  >
                    <Disclosure.Panel className="pb-4">
                      <p
                        className="pl-4 text-neutral-800 text-sm md:text-base border-l border-neutral-200 [&>a]:underline [&>a]:text-orange-600"
                        dangerouslySetInnerHTML={{ __html: answer }}
                      />
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </section>
  )
}
