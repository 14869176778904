import { SVGProps } from 'react'

export const IconWindow = ({
  color = 'currentColor',
  size = 24,
  ...props
}: SVGProps<SVGSVGElement> & { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.879 3.94h12.25M1.754 12.687h10.5a.875.875 0 0 0 .875-.875V2.19a.875.875 0 0 0-.875-.875h-10.5a.875.875 0 0 0-.875.875v9.624c0 .484.392.875.875.875Z"
      stroke={color}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
