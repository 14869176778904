import { Helmet } from 'react-helmet'
import { metaData } from '../config'

export const DefaultSEO = () => {
  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaData.title} />
      <meta name="twitter:description" content={metaData.description} />
      <meta name="twitter:site " content="@Peakfijn" />
      <meta name="twitter:image " content="%PUBLIC_URL%/og-image.png" />
      <meta name="twitter:image:alt " content="Peakfijn logo" />
      <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
      <meta name="og:image:alt" content="Peakfijn logo" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="og:title" content={metaData.title} />
      <meta name="og:description" content={metaData.description} />
      <meta name="og:url" content="%PUBLIC_URL" />
    </Helmet>
  )
}
