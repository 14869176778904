import { useTranslation } from 'react-i18next'
import { Button } from '../components/Button'
import { IconApple } from '../components/IconApple'
import { IconArrowTopRight } from '../components/IconArrowTopRight'
import { IconGoogle } from '../components/IconGoogle'
import { IconWindow } from '../components/IconWindow'
import { useBreakpoint } from '../hooks/useBreakpoint'

const images = {
  oaky: require('../assets/cases/oaky.webp'),
  'pro-parents': require('../assets/cases/pro-parents.webp'),
  cabbit: require('../assets/cases/cabbit.webp'),
  'sman-waarnemingen': require('../assets/cases/sman-waarnemingen.webp'),
  sfered: require('../assets/cases/sfered.webp'),
}

type Project = {
  title: string
  description: string
  image: keyof typeof images
  figures: { number: string; description: string }[]
  links: { type: 'app-store' | 'play-store' | 'web'; url: string }[]
}

export function Cases() {
  const isMediumDevice = useBreakpoint('sm')
  const { t } = useTranslation('cases')

  return (
    <section className="mt-[5.25rem] lg:mt-[10rem]">
      <div className="outer-container">
        <h2 className="h2">{t('title')}</h2>
        <p className="subtitle">{t('subtitle')}</p>

        <div className="space-y-16 sm:space-y-[7.5rem] mt-10 lg:mt-[7.5rem] mx-auto max-w-2xl lg:max-w-none">
          {(t('projects', { returnObjects: true }) as Project[])
            .filter((project) => project.image !== 'pro-parents') // Pro Parents does not wish to be listed yet as of 2022-10-20
            .map((project, index) => (
              <div
                key={index}
                className={`lg:flex lg:items-center ${
                  index % 2 ? 'lg:flex-row-reverse' : 'lg:flex-row'
                }`}
              >
                <div className="lg:w-1/2 xl:w-[38.75rem]">
                  <img
                    loading="lazy"
                    className="w-full"
                    alt="SMan Waarnemingen"
                    src={images[project.image]}
                  />
                </div>
                <div className="lg:w-1/2 xl:w-[31.25rem]">
                  <div className="lg:px-[5rem] xl:px-[6rem]">
                    <h3 className="h3">{project.title}</h3>
                    <p className="body mt-2 lg:mt-3">{project.description}</p>
                    <div className="flex space-x-8 mt-5 md:mt-6">
                      {project.figures.map((figure, index) => {
                        return (
                          <div key={index}>
                            <p className="h3 !text-neutral-800">
                              {figure.number}
                            </p>
                            <p className="text-neutral-600 text-xs md:text-sm whitespace-nowrap">
                              {figure.description}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                    <div className="flex space-x-2 mt-5 md:mt-6">
                      {project.links.map((link, index) => {
                        const Icon = {
                          'app-store': IconApple,
                          'play-store': IconGoogle,
                          web: IconWindow,
                        }[link.type]

                        const label = {
                          'app-store': 'App Store',
                          'play-store': 'Play Store',
                          web: t('viewWebsite'),
                        }[link.type]

                        return (
                          <Button
                            IconLeftComponent={
                              Icon ? <Icon size={14} /> : undefined
                            }
                            IconRightComponent={<IconArrowTopRight size={10} />}
                            as="a"
                            variant="outline"
                            href={link.url}
                            key={index}
                            size={isMediumDevice ? 'medium' : 'small'}
                          >
                            {label}
                          </Button>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
