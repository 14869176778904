import { useBreakpoint } from '../hooks/useBreakpoint'
import { Button } from '../components/Button'
import phones from '../assets/hero/hero-phones-desktop.webp'

import { useTranslation } from 'react-i18next'

export function JobsHero() {
  const isMediumDevice = useBreakpoint('sm')
  const { t } = useTranslation('jobs')

  return (
    <section className="mt-[4rem] lg:mt-[11.5rem]">
      <div className="inner-container relative">
        <div className="max-w-sm">
          <h1 className="font-display font-bold text-2xl md:text-3xl leading-none">
            {t('title1')}
            {isMediumDevice ? <br /> : ' '}
            <em className="text-orange-600 not-italic">{t('title2')}</em>
          </h1>
          <p className="mt-4 md:mt-6 body">{t('subtitle')}</p>
          <Button
            as="a"
            className="mt-5 md:mt-8 inline-flex"
            size={isMediumDevice ? 'large' : 'medium'}
            href={t('url')}
          >
            {t('cta')}
          </Button>
        </div>

        <img
          src={phones}
          alt={t('phonesAlt')}
          className="w-[400px] absolute -right-5 top-[-10rem] hidden lg:block"
        />
      </div>
    </section>
  )
}
